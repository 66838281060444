<template lang="pug">
  footer.footer
    div.container
      router-link.footer__logo(:to="{ name: 'HomePage' }")
        img(:src="Logo" alt="logo")
      ul
        li
          router-link(:to="{ name: 'HomePage' }") {{ $t('footer.home') }}
        li
          router-link(:to="{ name: 'ToolsPage' }") {{ $t('footer.functions') }}
        //li
        //  router-link(:to="{ name: 'PricesPage' }") {{ $t('footer.price') }}
        li
          router-link(:to="{ name: 'ContactPage' }") {{ $t('footer.contact') }}
        li
          router-link(:to="{ name: 'AgreementPage' }") {{ $t('footer.agreement') }}
        //li
        //  router-link(:to="{ name: 'BlogPage' }") {{ $t('footer.blog') }}
        li
          router-link(:to="{ name: 'DemoPage' }") {{ $t('footer.demo') }}
        li
          v-icon(
            tag="a"
            href="https://docs.easypon.in/how-to-install-easypon"
            target="_blank"
            dense
          ).docs-link mdi-help-circle-outline
      div.footer__pay
        img(
          :src="Visa"
          alt="visa"
        )
        img(
          :src="MasterCard"
          alt="mastercard"
        )
      div.footer__copy
        .footer__copy-wrap
          p © {{ new Date().getFullYear() }} EasyPON. All rights reserved.
          router-link.footer__policy(:to="{ name: 'PolicyPage' }") {{ $t('footer.privacy') }}
          router-link.footer__policy(:to="{ name: 'ReturnPage' }") {{ $t('footer.return') }}
          router-link.footer__policy(:to="{ name: 'TermsPage' }") {{ $t('footer.terms') }}
          router-link.footer__policy(:to="{ name: 'AgreementPage' }") {{ $t('footer.agreement') }}
        div.footer__copy-box
          p
            a(
              href="tel:380931776917"
              target="_blank"
            ) +380931776917
          p
            a(
              href="tel:48459566917"
              target="_blank"
            ) +48459566917
          p Email:
            a(href="mailto:sales@easypon.in") sales@easypon.in
</template>

<script>
import Visa from '@/assets/images/visa.svg'
import MasterCard from '@/assets/images/master.svg'
import Logo from '@/assets/images/logo.svg'

export default {
  name: 'FooterBlock',
  data () {
    return {
      Visa,
      MasterCard,
      Logo
    }
  }
}
</script>
